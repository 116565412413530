<template>
  <v-app>
    <v-main>
      <v-card
        min-height="490px"
        height="450px"
        width="100%"
        max-width="340px"
        style="
          position: absolute;
          top: calc(50% - 240px);
          right: 10%;
          z-index: 1000;
          margin: auto;
          background-color: rgba(255, 255, 255);
          border-radius: 30px;
          box-shadow: 0 0 40px #383838;
          overflow: hidden;
        "
      >
        <img :src="logoPath" width="100%" alt="Logo" />
        <v-card-title
          class="justify-center pa-1"
          style="font-size: 1.2rem; font-weight: 500; color: #898989"
        >
          LOGIN
        </v-card-title>

        <v-stepper
          v-model="loginStep"
          class="elevation-0"
          style="background-color: transparent; height: 275px"
        >
          <v-stepper-items>
            <v-stepper-content
              step="1"
              class="elevation-0"
              style="height: 255px"
            >
              <v-form ref="formUserName" v-model="formUserName">
                <div class="mb-12 mt-2">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        ref="emailid"
                        label="Registered Email Id"
                        outlined
                        v-model="emailId"
                        :rules="[rules.req, rules.email]"
                        @keydown.enter.stop.prevent
                        @keyup.enter="validateEmail"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div
                  style="
                    text-align: center;
                    position: absolute;
                    bottom: 1px;
                    left: 0px;
                    width: 100%;
                  "
                >
                  <v-btn
                    color="primary"
                    style="width: 100%"
                    x-large
                    @click="validateEmail"
                    :disabled="!formUserName"
                    :loading="isLoading"
                  >
                    Continue
                  </v-btn>
                </div>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2" style="height: 255px">
              <v-form ref="formPassword" v-model="formPassword">
                <div class="mb-2">
                  <div class="pt-0 pb-1 mb-3 justify-center">
                    <v-chip
                      @click="loginStep = 1"
                      style="width: 100%; text-align: center !important"
                      ><v-icon left>mdi-keyboard-backspace</v-icon>
                      {{ emailId }}
                    </v-chip>
                  </div>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-text-field
                        ref="password"
                        :label="plabel"
                        placeholder=""
                        outlined
                        type="password"
                        hint="1 Numeric Letter(0-9), 1 Capital Letter(A-Z), and 1 Special Character(@#$)"
                        persistent-hint
                        v-model="password"
                        :rules="passwordRules"
                        @keydown.enter.stop.prevent
                        
                      ></v-text-field>
                      <v-btn
                        color="primary"
                        @click="forgotPassword"
                        v-if="plabel == 'Password'"
                        text
                        >Forgot Password?</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <div
                  style="
                    text-align: center;
                    position: absolute;
                    bottom: 1px;
                    left: 0px;
                    width: 100%;
                  "
                >
                  <v-btn
                    color="primary"
                    style="width: 100%"
                    :disabled="!formPassword"
                    x-large
                    @click="createPassword"
                    :loading="isLoading"
                  >
                    Login
                  </v-btn>
                </div>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="3" style="height: 255px">
              <v-form ref="formOtp" v-model="formOtp">
                <div class="mb-4">
                  <div class="pt-3 pb-3 mb-3 justify-center">
                    <v-chip
                      @click="loginStep = 1"
                      style="width: 100%; text-align: center !important"
                      ><v-icon left>mdi-keyboard-backspace</v-icon>
                      {{ emailId }}
                    </v-chip>
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Enter OTP"
                        outlined
                        v-model="otp"
                        hint="Please enter OTP sent to your mail id."
                        persistent-hint
                        :rules="[rules.req]"
                        @keydown.enter.stop.prevent
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div
                  style="
                    text-align: center;
                    position: absolute;
                    bottom: 1px;
                    left: 0px;
                    width: 100%;
                  "
                >
                  <v-btn
                    color="primary"
                    :loading="isLoading"
                    x-large
                    style="width: 100%"
                    :disabled="!formOtp"
                    @click="validateOtp"
                  >
                    Validate
                  </v-btn>
                </div>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>

      <video-background
        :src="bgVPath"
        :poster="bgIPath"
        :sources="[{ src: bgIPath, res: 900, autoplay: true }]"
        style="height: 100vh"
        overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
      >
      </video-background>

      <v-alert v-if="alert.show" :type="alert.type"></v-alert>
      <v-snackbar top v-model="alert.show" :color="alert.color">
        {{ alert.msg }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import VideoBackground from "vue-responsive-video-background-player";
export default {
  layout: "login",
  components: { "video-background": VideoBackground },
  props: { EventUrl: String, VenueUrl: String },
  data() {
    return {
      alert: {
        color: "success",
        msg: "",
        show: false,
      },
      plabel: "Password",
      logoPath: "",
      isLoading: false,
      loginStep: 1,
      emailId: "",
      password: "",
      otp: "",
      otpServer: "",
      formUserName: false,
      formPassword: false,
      formOtp: false,
      formCreatePass: false,
      appData: {
        user_id: "",
        password: "",
      },

      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 6) || "Password must have 6+ characters",
        (v) => /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
        (v) => /(?=.*\d)/.test(v) || "Must have one number",
        (v) => /([@#$])/.test(v) || "Must have one special character [@#$]",
      ],

      rules: {
        req: (val) => (val !== null && val !== "") || "Required!",
        email: (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      },
      items: [],
      bgIPath: "",
      bgVPath: "",
      // result: {
      //   logo: "https://accred.dnanetworks.in/assets/ipl-2020/logo.jpg",
      //   header: "https://accred.dnanetworks.in/assets/ipl-2020/header.jpg",
      //   banner: [
      //     { src: "https://accred.dnanetworks.in/assets/ipl-2020/banner1.jpg" },
      //     { src: "https://accred.dnanetworks.in/assets/ipl-2020/banner2.jpg" },
      //     { src: "https://accred.dnanetworks.in/assets/ipl-2020/banner3.jpg" },
      //   ],
      // },
    };
  },
  async mounted() {
    // this.logoPath = this.$apiPhotoPath + "/assets/" + this.EventUrl + "/logo.jpg";
    // this.$refs.emailid.focus();
    // this.items = [
    //   {
    //     src: this.$apiPhotoPath + "/assets/" + this.EventUrl + "/bg1.jpg",
    //   },
    //   {
    //     src: this.$apiPhotoPath + "/assets/" + this.EventUrl + "/bg2.jpg",
    //   },
    // ];

    this.logoPath = this.$apiPathNew + "/assets/" + this.EventUrl + "/logo.jpg";
    this.bgIPath = this.$apiPathNew + "/assets/" + this.EventUrl + "/bg.jpg";
    this.bgVPath = this.$apiPathNew + "/assets/" + this.EventUrl + "/bg.mp4";
  },
  methods: {
    showMsg: function (color, msg) {
      this.alert.color = color;
      this.alert.msg = msg;
      this.alert.show = true;
    },
    forgotPassword: async function () {
      this.isLoading = true;
      await this.$axios
        .put("/Media/forgottenpassword/" + this.emailId + "/" + this.EventUrl)
        .then((res) => {
          var oRes = res.data;
          if (oRes.status == "Success") {
            this.loginStep = 1;
            this.showMsg("success", "Enter registered email id for procceed!");
          }
          this.isLoading = false;
        });
    },
    validateEmail: async function () {
      this.isLoading = true;
      await this.$axios
        .get("/Media/checkApplicant/" + this.emailId + "/" + this.EventUrl)
        .then((res) => {
          var oRes = res.data;
          //console.log(res.data);
          if (oRes.status == "Success") {
            if (oRes.result == "LOGIN") {
              this.loginStep = 2;
            } else if (oRes.result == "NOT EXIST") {
              this.showMsg("error", "Invalid Email Id!");
            } else {
              this.loginStep = 3;
              this.otpServer = oRes.result.otp;
            }
          } else if (oRes.status == "Failed") {
            this.showMsg("error", "Invalid Email Id!");
          } else {
            this.showMsg("error", "Invalid Email Id!");
          }
          this.isLoading = false;
        });
    },
    validateOtp: function () {
      if (this.otpServer == this.otp) {
        this.plabel = "Create New Password";
        this.loginStep = 2;
      } else {
        this.showMsg("error", "Invalid OTP!");
      }
    },
    createPassword: async function () {
      this.appData.user_id = this.emailId;
      this.appData.password = this.password;
      this.isLoading = true;
      //console.log(this.appData);
      await this.$axios
        .put("/Media/changeApplicantPassword/" + this.EventUrl, this.appData)
        .then((res) => {
          //console.log(res.data);
          if (res.data.status == "Success") {
           // console.log(res.data.result);
            this.$store.commit("setEventInfo", res.data.result);
            this.$store.commit("setEventVenueUrl", {e:this.EventUrl, v:this.VenueUrl});
            // this.$router.push(
            //   "/" + this.EventUrl + "/" + this.VenueUrl + "/profile"
            // );

             window.location.replace("/" + this.EventUrl + "/" + this.VenueUrl + "/profile");
          } else {
            this.showMsg("error", "Invalid Password!");
          }
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.text-center {
  text-align: center;
}
</style>